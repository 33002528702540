<template>
  <div class="component">
    <vue-apex-charts
      ref="popularChart"
      type="bar"
      height="240"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    data: Array,
    title: String,
    option: String,
  },
  data() {
    return {
      maxNumToShow: 10,
      count: 0,
      selectedOption: 'In Percentage',
      series: [{
        name: 'chart',
        data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380],
      }],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
          toolbar: { show: false },
          zoom: { enabled: false },
        },
        colors: ['#7367f0'],
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            barHeight: '60%',
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          tickAmount: 5,
          categories: ['South Korea', 'Canada', 'United Kingdom', 'Netherlands', 'Italy', 'France', 'Japan',
            'United States', 'China', 'Germany',
          ],
          labels: {
            formatter(val) {
              return val + '%'
            },
          },
        },
        toolbar: { show: false },

      },
    }
  },
  computed: {
    sortedData() {
      const temp = this.data

      if (temp.length > 5) {
        return temp.sort((a, b) => b.value - a.value).slice(0, 5)
      }
      return temp.sort((a, b) => b.value - a.value)
    },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        this.getStats(this.option)
      },
    },
    option: {
      deep: true,
      handler() {
        this.getStats(this.option)
      },
    },
  },
  created() {
    this.getStats('In Percentage')
  },
  methods: {
    getStats(type) {
      this.selectedOption = type
      this.chartOptions.xaxis.categories = []
      this.series[0].data = []
      this.series[0].name = this.title

      this.sortedData.forEach(stat => {
        this.chartOptions.xaxis.categories.push(stat.key)

        if (type === 'In Percentage') {
          this.series[0].data.push(stat.value)
        } else {
          this.series[0].data.push(stat.count)
        }
      })

      if (this.$refs.popularChart) {
        this.$refs.popularChart.updateOptions({
          xaxis: {
            categories: this.chartOptions.xaxis.categories,
            labels: {
              formatter(val) {
                if (type === 'In Percentage') {
                  return val + '%'
                }
                return val
              },
            },
          },
        })
      }
    },
  },

}
</script>

<style>

</style>
